import React from 'react';
import * as styles from './questionsContent.module.scss';

const QuestionsContent = ({ temp }) => (
  <div className={styles.questionsContent}>
    {
      temp.map((item, i) => (
        <div className={styles.questionsList} key={i}>
          <p className={styles.title}>{item.question}</p>
          <p dangerouslySetInnerHTML={{ __html: item.answer }}></p>
        </div>
      ))
    }
  </div>
);

export default QuestionsContent;
